// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyCFjVNEkwYNyJO87C9indCmqgQJTUhXt4Q",
//   authDomain: "ipartydjmix-d90d7.firebaseapp.com",
//   projectId: "ipartydjmix-d90d7",
//   storageBucket: "ipartydjmix-d90d7.firebasestorage.app",
//   messagingSenderId: "406060922015",
//   appId: "1:406060922015:web:049551f8df06093083deaa",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// // Initialize Firestore
// const db = getFirestore(app);
// const storage = getStorage(app);

// export { db, storage };

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAkPB4OA81MmhPRtKFRdZVzrklgSFTjbY4",
  authDomain: "messangeripdm.firebaseapp.com",
  projectId: "messangeripdm",
  storageBucket: "messangeripdm.firebasestorage.app",
  messagingSenderId: "991057639817",
  appId: "1:991057639817:web:8da8313bd3a90278e58958",
  measurementId: "G-RLW8909TZN",
};

//  Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
