import React, { useEffect, useState } from "react";
import { collection, query, where, orderBy, limit, onSnapshot, getDocs } from "firebase/firestore";
import { db } from "../../Firebase Files/firebaseConfig";

function ChatLastMessage({ itemId, currentUserId }) {
  const [lastMessage, setLastMessage] = useState("Loading last message...");
  const [lastMessageTime, setLastMessageTime] = useState("");

  useEffect(() => {
    // Set up a listener to get the last message in real-time
    async function setupLastMessageListener() {
      try {
        // Query to find the chatroom between the current user and the specified item ID
        const chatRoomsQuery = query(collection(db, "Chats"), where("participants", "array-contains", currentUserId));

        const chatRoomsSnapshot = await getDocs(chatRoomsQuery);
        let chatRoomId = null;
        chatRoomsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.participants.includes(itemId)) {
            chatRoomId = doc.id;
          }
        });

        if (!chatRoomId) {
          setLastMessage("No messages yet");
          return;
        }

        // Real-time listener for the latest message in the chatroom's messages subcollection
        const messagesQuery = query(
          collection(db, "Chats", chatRoomId, "messages"),
          orderBy("timestamp", "desc"),
          limit(1)
        );

        const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
          if (!snapshot.empty) {
            const latestMessage = snapshot.docs[0].data();
            setLastMessage(
              latestMessage?.contactShare
                ? "🪪ContactCard"
                : latestMessage?.deleted
                ? "message has been deleted"
                : latestMessage?.fileURLs?.length > 0
                ? "🖇️attachments"
                : latestMessage?.text || "No messages yet"
            );
            setLastMessageTime(
              new Date(latestMessage?.timestamp * 1000).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
            );
          } else {
            setLastMessage("No messages yet");
          }
        });

        // Clean up the listener on unmount
        return () => unsubscribe();
      } catch (error) {
        console.error("Error setting up listener for last message:", error);
        setLastMessage("Error loading message");
      }
    }

    setupLastMessageListener();
  }, [itemId, currentUserId]);


  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <p className="m-0">{lastMessage} </p>
      <p className="m-0">{lastMessageTime}</p>
    </div>
  );
}

export default ChatLastMessage;
