import { useNavigate } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { formatDate } from "../../../utils/Utils";

const EventCard = ({ eventType, eventName, eventDate, Venue, id }) => {
  const navigate = useNavigate();

  function OpenEventDetails() {
    navigate(`/my-events/event-details/${id}`);
  }

  function OpenMakePayment() {
    navigate(`/my-events/event-details/${id}`);
  }

  return (
    <>
      {/* <Col className="px-3 my-2" style={{ maxWidth: '500px' }}> */}
      <Col className="px-3 my-2">
        <Card className="card-bordered">
          <CardHeader className="border-bottom">
            {formatDate(eventDate)}
          </CardHeader>
          <CardBody className="card-inner">
            <CardTitle tag="h5">{eventType}</CardTitle>
            <CardText>
              <p className="mb-1">
                <strong className="text-dark ">Event Name: </strong> {eventName}
              </p>
              <p className="mb-1">
                <strong className="text-dark">Event Date: </strong>
                {formatDate(eventDate)}
              </p>
              {Venue && (
                <p className="mb-0">
                  <strong className="text-dark">Venue: </strong> {Venue}
                </p>
              )}
            </CardText>
            <div>
              <Row>
                <Col sm={12}>
                  <Button
                    color="primary btnprimary"
                    className=" mt-2 d-flex justify-content-center align-item-center"
                    block
                    onClick={OpenEventDetails}
                  >
                    <em class="icon ni ni-calender-date"></em>
                    <span>Event Details</span>
                  </Button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default EventCard;
