import React from "react";
import { Col, Row } from "reactstrap";
import PastEvents from "./components/PastEvents";
import UpcommingEvents from "./components/UpcommingEvents";
import events from "../../assets/images/Events.svg";

const MyEvents = () => {
  return (
    <>
      {/* <Row className="d-flex align-items-center"> */}
        {/* <Col xs="12" md="6">
          <img src={events} alt="Welcome" />
        </Col> */}
        <div className="">
          <UpcommingEvents />
        </div>
      {/* </Row> */}
      <div className="px-2">
        <PastEvents />
      </div>
    </>
  );
};  

export default MyEvents;
