import React, { useEffect, useState } from "react";
import { Button, CardTitle } from "reactstrap";
import { Icon, Progress } from "../../../components/Component";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";
import { getCookie } from "../../../utils/Utils";

const TotalEvent = () => {
  const [eventData, setEventData] = useState({
    totalEventCount: 0,
    completedEventCount: 0,
    incompleteEventCount: 0,
    pendingEventCount: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchEventData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/client-dashboard/get-client-dashboard-data`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch event data");
      }
      const data = await response.json();
      console.log("Output:".data);
      // Ensure data is in the correct format
      const formattedData = {
        totalEventCount: data?.data?.totalEventCount || 0,
        completedEventCount: data?.data?.completedEventCount || 0,
        incompleteEventCount: data?.data?.incompleteEventCount || 0,
        pendingEventCount: data?.data?.pendingEventCount || 0,
      };

      setEventData(formattedData);
    } catch (error) {
      console.error("Error fetching event data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  const progressValue = isNaN(
    (eventData.completedEventCount / eventData.totalEventCount) * 100
  )
    ? 0
    : (
        (eventData.completedEventCount / eventData.totalEventCount) *
        100
      ).toFixed(2);

  return (
    <React.Fragment>
      <div className="border rounded p-4 my-2">
        <div className="card-title-group align-start mb-2 align-center">
          <CardTitle>
            <h4 className="title">Events</h4>
          </CardTitle>
          <div className="card-tools">
            <Button
              className="text-white px-4 p-0 btnprimary"
              onClick={() => navigate("/my-events")}
            >
              <motion.span whileHover={{ y: 1 }}>
                <span className="sub-text text-white p-1">List</span>
              </motion.span>
            </Button>
          </div>
        </div>
        <div className="row text-center justify-around mt-5">
          <div className="nk-sale-data col-auto">
            <span className="fw-bold text-body">Total Events</span>
            <span className="amount">
              {typeof eventData.totalEventCount === "number"
                ? eventData.totalEventCount
                : "0"}
            </span>
          </div>

          <div className="nk-sale-data col-auto">
            <span className="fw-bold text-body">Completed Events</span>
            <span className="amount">
              {typeof eventData.completedEventCount === "number"
                ? eventData.completedEventCount
                : "0"}
            </span>
          </div>

          <div className="nk-sale-data col-auto">
            <span className="fw-bold text-body"> Incomplete Events</span>
            <span className="amount">
              {typeof eventData.incompleteEventCount === "number"
                ? eventData.incompleteEventCount
                : "0"}
            </span>
          </div>

          <div className="nk-sale-data col-auto">
            <span className="fw-bold text-body"> Pending Events</span>
            <span className="amount">
              {typeof eventData.pendingEventCount === "number"
                ? eventData.pendingEventCount
                : "N/A"}
            </span>
          </div>
        </div>
        <div className="mx-3 mt-5">
          <Progress
            className="progress-lg text-body progress"
            striped
            value={isNaN(progressValue) ? 0 : progressValue}
          ></Progress>
          <div className="fw-bold text-center mt-5 mb-2 text-body">
            Total Events Completed Ratio
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TotalEvent;
