import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Input, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { db } from "../../Firebase Files/firebaseConfig";
import { addDoc, collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { UserAvatar } from "../../components/Component";
import { CommonToaster, findUpper } from "../../utils/Utils";
import { postRequest } from "../../api-service";

const SendMessageModal = ({ isOpen, toggle, forwardMessages, currentUser, shareableContact, shareFiles }) => {
  const [filterText, setFilterText] = useState("");
  const [contact, setContact] = useState([]);
  const [firebaseContact, setFirebaseContact] = useState([]);
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [sentMessages, setSentMessages] = useState([]);
  const [loading, setLoading] = useState(null);
  const [showToast, setShowToast] = useState(false);
  // console.log("shareableContact", shareableContact);
  // console.log("shareFiles", shareFiles);

  const toggleToast = () => {
    setShowToast(!showToast); // Toggle the showToast state
  };

  const getIpdmUsers = async () => {
    const formData = new FormData();
    formData.append("limit", 20);
    formData.append("search", filterText);

    try {
      const res = await postRequest("api/v1/user/users-list", formData);

      if (res && res.data?.users && filterText) {
        setContact((prevContacts) => [...prevContacts, ...res.data?.users]);
      } else {
        setContact(contact);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    getIpdmUsers();
  }, [filterText]);

  useEffect(() => {
    const getUsers = () => {
      const chatCollection = collection(db, "Users");

      const unsubscribe = onSnapshot(
        chatCollection,
        (snapshot) => {
          const chatList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const sortedChatList = chatList.filter((user) => user.id !== currentUser?.id).sort((a, b) => 0);
          setContact(sortedChatList);
          setFirebaseContact(sortedChatList);
        },
        (error) => {
          console.error("Error fetching chat data: ", error);
        }
      );

      return () => unsubscribe();
    };

    getUsers();
  }, [currentUser]);

  useEffect(() => {
    if (filterText !== "") {
      const filteredObject = contact.filter((item) => {
        return (
          item.name?.toLowerCase().includes(filterText.toLowerCase()) ||
          item.username?.toLowerCase().includes(filterText.toLowerCase())
        );
      });

      const uniqueFilteredObject = filteredObject.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );

      setFilteredChatList([...uniqueFilteredObject]);
    } else {
      const uniqueContactList = contact.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );

      setFilteredChatList([...uniqueContactList]);
      setContact(firebaseContact);
    }
  }, [filterText, contact, firebaseContact]);

  useEffect(() => {
    if (!isOpen) {
      setSentMessages([]);
    }
  }, [isOpen]);

  const handleSendMessage = async (id) => {
    setLoading(id);

    const chatId = await createNewChat(id); // Fetch or create the correct chat ID dynamically
    if (chatId && currentUser && shareableContact.length === 0 && shareFiles.length === 0) {
      const messagesRef = collection(db, "Chats", chatId, "messages");
      try {
        await addDoc(messagesRef, {
          text: forwardMessages,
          senderId: currentUser.id,
          senderName: currentUser.name,

          forwarded: true,
          timestamp: new Date(),
        });

        setSentMessages((prev) => [...prev, id]); // Track sent message for this user
        setLoading(null);
        setShowToast(!showToast); // Toggle the showToast state
      } catch (error) {
        setLoading(null);
        setShowToast(!showToast); // Toggle the showToast state

        console.error("Error sending message:", error);
      }
    }

    if (chatId && currentUser && shareableContact) {
      const messagesRef = collection(db, "Chats", chatId, "messages");
      try {
        await addDoc(messagesRef, {
          text: forwardMessages,
          senderId: currentUser.id,
          senderName: currentUser.name,

          forwarded: true,
          timestamp: new Date(),

          contactShare: true,
          contactInfo: {
            createdAt: shareableContact.contactInfo.createdAt,
            id: shareableContact.contactInfo.id,
            name: shareableContact.contactInfo.name,
            profilePic: shareableContact.contactInfo.profilePic,
          },
        });
        setSentMessages((prev) => [...prev, id]); // Track sent message for this user
        setLoading(null);
        setShowToast(!showToast); // Toggle the showToast state
      } catch (error) {
        setLoading(null);

        setShowToast(!showToast); // Toggle the showToast state

        console.error("Error sending message:", error);
      }
    }

    if (chatId && currentUser && shareFiles) {
      const messagesRef = collection(db, "Chats", chatId, "messages");
      try {
        await addDoc(messagesRef, {
          text: shareFiles.text || "",
          senderId: currentUser.id,
          senderName: currentUser.name,
          forwardedFiles: true,
          fileURLs: shareFiles.fileURLs, // Store the uploaded file URLs in Firestore
          timestamp: new Date(),
        });
        setSentMessages((prev) => [...prev, id]); // Track sent message for this user
        setLoading(null);
        setShowToast(!showToast); // Toggle the showToast state
      } catch (error) {
        setLoading(null);

        setShowToast(!showToast); // Toggle the showToast state

        console.error("Error sending message:", error);
      }
    }
  };

  const createNewChat = async (id) => {
    const currentUserId = currentUser?.id;
    if (!currentUserId) {
      console.error("No user logged in");
      return;
    }

    const q = query(collection(db, "Chats"), where("participants", "array-contains", currentUserId));

    const querySnapshot = await getDocs(q);
    let chatId = null;

    querySnapshot.forEach((doc) => {
      const participants = doc.data().participants;
      if (participants.includes(id)) {
        chatId = doc.id; // Chat already exists
      }
    });

    if (!chatId) {
      try {
        const newChat = await addDoc(collection(db, "Chats"), {
          participants: [currentUserId, id],
          createdAt: new Date(),
        });
        chatId = newChat.id; // Chat was newly created
      } catch (error) {
        console.error("Error creating new chat:", error);
      }
    }

    // Dynamically select the correct user with the chatId
    selectUser({ chatId, id });
    return chatId;
  };

  const selectUser = (user) => {
    setSelectedUser(user); // Update the selected user state
    setUsers((prevUsers) =>
      prevUsers.map((u) => (u.id === user.id ? { ...u, selected: true } : { ...u, selected: false }))
    );
  };

  //   const selectUser = (user) => {
  //     setSelectedUser(user);
  //     setUsers(users.map((u) => (u.id === user.id ? { ...u, selected: true } : { ...u, selected: false })));
  //   };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#1f2937", color: "#fff" }}>
        Send Message
      </ModalHeader>
      <ModalBody>
        <Input
          type="text"
          placeholder="Search Contacts..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          style={{ marginBottom: "5px" }}
        />

        <ListGroup flush style={{ height: 400, overflowY: "auto" }}>
          {filteredChatList.map((user) => (
            <ListGroupItem key={user.id} className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <UserAvatar
                  image={user?.profilePic || user?.profile_image_path}
                  size="md"
                  text={findUpper(user?.name || user?.username)}
                />
                <span style={{ marginLeft: "10px" }}>{user.name || user.username}</span>
              </div>
              <Button
                color="secondary"
                onClick={() => handleSendMessage(user?.id)}
                disabled={sentMessages.includes(user.id)}
              >
                {loading === user.id ? <Spinner size={"sm"} /> : sentMessages.includes(user.id) ? "Sent" : "Send"}
              </Button>
            </ListGroupItem>
          ))}
        </ListGroup>
        {/* Render the CommonToaster */}
        <CommonToaster
          showToast={showToast}
          message={"Sent Successfully.."} // Pass the dynamic message to the toast
          toggleToast={toggleToast}
          theme={"green"}
        />
      </ModalBody>
    </Modal>
  );
};

export default SendMessageModal;
