import React, { useContext, useEffect, useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Button,
  Progress,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component";
import { collection, query, where, getDocs, orderBy, limit } from "firebase/firestore";

// import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { findUpper, getInitiatals } from "../../utils/Utils";
import { ChatContext } from "./ChatContext";
import { useTheme } from "../../layout/provider/Theme";
import DisplayPage from "../components/UtilDisplay";
import ContactCard from "../components/ContactCard";
import pdfImg from "../../images/reports.png";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import { removeChattingId } from "../../Firebase Files/firebase-service";
import FileMessage from "../components/FileMessage";
import { db } from "../../Firebase Files/firebaseConfig";
import ChatLastMessages from "../components/ChatLastMessages";

export const MeChat = ({
  item,
  chat,
  onRemoveMessage,
  handleMsgAction,
  messageRefs,
  scrollToMessage,
  select, // Added prop to conditionally render checkboxes
  handleSelectMessage,
  selectedMessageId,
  chatItemClick,
  uploading,
  uploadingFiles,
  lastMessageId,
}) => {
  const theme = useTheme();

  useEffect(() => {
    return () => {
      // Revoke all URLs after component unmount
      Object.keys(uploadingFiles).forEach((fileName) => {
        const fileObject = uploadingFiles[fileName].file;
        if (fileObject instanceof File) {
          URL.revokeObjectURL(fileObject);
        }
      });
    };
  }, [uploadingFiles]);

  // console.log("item mechat", item.fileURLs);

  return (
    <div
      className="chat is-me"
      ref={(el) => (messageRefs.current[item.id] = el)} // Set ref for each message
    >
      <div className="chat-content">
        <div className="chat-bubbles">
          <div className="chat-bubble" key={item?.id}>
            {item?.deleted === true ? (
              <div className="chat-msg border bg-white text-muted">Message has been deleted</div>
            ) : (
              <React.Fragment>
                <div>
                  <ul className="chat-meta d-flex" style={{ listStyleType: "none", paddingLeft: 0 }}>
                    <li className="text-primary">{item?.edited && <p>edited</p>}</li>
                  </ul>

                  {item?.reply && (
                    <div
                      className="chat-bubble pointer"
                      key={item.id}
                      onClick={() => scrollToMessage(item.replyingToMessageId)}
                    >
                      <div className={`chat-msg bg-white text-${theme.skin === "dark" ? "white" : "dark"}`}>
                        {item.replyingMsg
                          .slice(0, 80)
                          .split(" ")
                          .map((word, i) =>
                            word.match(/(https?:\/\/[^\s]+)/g) ? (
                              <span key={i} href={word} target="_blank" rel="noopener noreferrer">
                                {word}
                                {item.replyingMsg.length > 80 ? " ..." : ""}
                              </span>
                            ) : (
                              <span key={i}>{word}</span>
                            )
                          )}
                      </div>
                    </div>
                  )}
                  <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    {item?.contactShare && (
                      <>
                        <ContactCard contact={item?.contactInfo} chatItemClick={chatItemClick} item={item} />
                      </>
                    )}
                    {item.fileURLs && item.fileURLs.length > 0 && (
                      <div
                        className={`chat-msg bg-${chat.chatTheme} ${
                          item?.contactShare || item?.forwarded ? "d-none" : ""
                        }`}
                      >
                        {item?.forwardedFiles && (
                          <div className="text-50 opacity-75 mb-2">
                            <Icon name="reply-all"></Icon>
                            forwarded
                          </div>
                        )}
                        <FileMessage item={item} pdfImg={pdfImg} />
                      </div>
                    )}

                    {item.text !== "" && (
                      <div
                        className={`chat-msg bg-${chat.chatTheme} ${item?.contactShare ? "d-none" : ""}`}
                        style={{
                          width: "fit-content",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          textAlign: "start",
                          fontSize: "13px",
                        }}
                      >
                        {item?.forwarded && item.text !== "" && (
                          <div className="text-50 opacity-75">
                            <Icon name="reply-all"></Icon>
                            forwarded
                          </div>
                        )}

                        {item.text}
                      </div>
                    )}

                    {/* Conditionally render the checkbox if `select` is true */}
                    {select && (
                      <div className="chat-checkbox" style={{ marginLeft: "10px", textAlign: "-webkit-right" }}>
                        <input
                          type="checkbox"
                          onChange={() => handleMsgAction(item.id, "select")}
                          checked={selectedMessageId?.includes(item.id)} // Keep checkbox selected based on selected IDs
                          style={{
                            width: "20px",
                            height: "20px",
                            transform: "scale(0.9)", // Make the checkbox bigger
                            accentColor: "red", // Set the checkbox theme to reddish
                            cursor: "pointer",
                          }}
                          onClick={() => handleSelectMessage(item.id)} // Select or deselect message
                        />
                      </div>
                    )}
                  </div>
                </div>
                {/* Show uploading indicator only on the last message */}

                <ul className="chat-msg-more">
                  <li className="d-none d-sm-block">
                    <UncontrolledDropdown>
                      {/* Kebab Menu Icon */}
                      <DropdownToggle tag="a" href="#options" className="btn btn-icon btn-sm btn-trigger">
                        <Icon name="more-v" />
                      </DropdownToggle>
                      {/* Dropdown Menu */}
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            onRemoveMessage(item.id);
                          }}
                        >
                          Delete
                        </DropdownItem>
                        <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleMsgAction(item.id, "copy");
                          }}
                        >
                          Copy
                        </DropdownItem>
                        <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleMsgAction(item.id, "reply");
                          }}
                        >
                          Reply
                        </DropdownItem>
                        <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleMsgAction(item.id, "edit");
                          }}
                        >
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleMsgAction(item.id, "selectMsg");
                          }}
                        >
                          Select messages
                        </DropdownItem>
                        <DropdownItem
                          onClick={(ev) => {
                            ev.preventDefault();
                            handleMsgAction(item.id, "forwardMsg", item);
                          }}
                        >
                          Forward
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </React.Fragment>
            )}
          </div>
        </div>
        <ul className="chat-meta">
          <li>
            {new Date(item.timestamp.seconds * 1000).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </li>
        </ul>
        {uploading && item.id === lastMessageId && (
          <div className={`chat-msg bg-${chat.chatTheme} uploading-indicator`}>
            <div className="uploading-header">
              <Spinner size="sm" color="primary" className="me-2" />
              <span className="uploading-text">Uploading Files...</span>
            </div>
            {Object.keys(uploadingFiles).map((fileName) => (
              <div key={fileName} className="file-upload-container">
                <div className="file-info">
                  <i className="file-icon bi bi-file-earmark-arrow-up" /> {/* Bootstrap Icon */}
                  <span className="file-name">{fileName}</span>
                </div>
                <Progress
                  animated
                  color="success"
                  value={Math.round(uploadingFiles[fileName])}
                  className="progress-bar"
                >
                  {Math.round(uploadingFiles[fileName])}%
                </Progress>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const YouChat = ({
  item,
  chat,
  handleMsgAction,
  messageRefs,
  scrollToMessage,
  select,
  handleSelectMessage,
  selectedMessageId,
  chatItemClick,
}) => {
  const theme = useTheme();
  console.log(messageRefs)
  return (
    <div
      className="chat is-you"
      ref={(el) => (messageRefs.current[item?.id] = el)} // Set ref for each message
    >
      <div className="chat-avatar">
        {chat?.profilePic ? (
          <img
            src={chat?.profilePic} // URL of the DJ's profile image
            alt="Profile"
            className="user-avatar"
            style={{ width: "45px", height: "45px", borderRadius: "50%" }} // Ensure the image is rounded
          />
        ) : (
          <UserAvatar
            text={getInitiatals(chat?.name)}
            theme="primary"
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          ></UserAvatar>
        )}
      </div>
      <div className="chat-content">
        <div className="chat-bubbles">
          {item?.deleted === true ? (
            <div className="chat-msg border bg-white text-muted">Message has been deleted</div>
          ) : (
            <div className="chat-bubble" key={item?.id}>
              <div>
                <ul className="chat-meta d-flex " style={{ listStyleType: "none", paddingLeft: 0 }}>
                  <li className="text-primary">{item?.edited && <p>edited</p>}</li>
                </ul>

                {item?.reply && (
                  <div
                    className="chat-bubble pointer"
                    key={item.id}
                    onClick={() => scrollToMessage(item?.replyingToMessageId)}
                  >
                    <div className={`chat-msg bg-white text-${theme.skin === "dark" ? "white" : "grey"}`}>
                      <p>
                        {item?.replyingMsg
                          .slice(0, 80)
                          .split(" ")
                          .map((word, i) =>
                            word.match(/(https?:\/\/[^\s]+)/g) ? (
                              <span key={i} href={word} target="_blank" rel="noopener noreferrer">
                                {word}
                                {item?.replyingMsg.length > 80 ? " ..." : ""}
                              </span>
                            ) : (
                              <span key={i}>{word}</span>
                            )
                          )}
                        {item.replyingMsg.length > 80 ? " ..." : ""}
                      </p>
                    </div>
                  </div>
                )}
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                  {item?.contactShare && (
                    <>
                      <ContactCard contact={item?.contactInfo} chatItemClick={chatItemClick} item={item} />
                    </>
                  )}
                  {item.fileURLs && item.fileURLs.length > 0 && (
                    <div
                      className={`chat-msg bg-${chat?.chatTheme} ${
                        item?.contactShare || item?.forwarded ? "d-none" : ""
                      }`}
                    >
                      {item?.forwardedFiles && (
                        <div className="text-50 opacity-75 mb-2">
                          <Icon name="reply-all"></Icon>
                          forwarded
                        </div>
                      )}
                      <FileMessage item={item} pdfImg={pdfImg} />
                    </div>
                  )}
                  {item.text !== "" && (
                    <div
                      className={`chat-msg bg-${chat.chatTheme} ${item?.contactShare ? "d-none" : ""}`}
                      style={{
                        width: "fit-content",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        textAlign: "start",
                        fontSize: "13px",
                      }}
                    >
                      {item?.forwarded && (
                        <div className="text-50 opacity-75">
                          <Icon name="reply-all"></Icon>
                          forwarded
                        </div>
                      )}

                      {item.text}
                    </div>
                  )}
                  {/* Conditionally render the checkbox if `select` is true */}
                  {select && (
                    <div className="chat-checkbox" style={{ marginLeft: "10px", textAlign: "-webkit-right" }}>
                      <input
                        type="checkbox"
                        onChange={() => handleMsgAction(item.id, "select")}
                        checked={selectedMessageId?.includes(item.id)} // Keep checkbox selected based on selected IDs
                        style={{
                          width: "20px",
                          height: "20px",
                          transform: "scale(0.9)", // Make the checkbox bigger
                          accentColor: "red", // Set the checkbox theme to reddish
                          cursor: "pointer",
                        }}
                        onClick={() => handleSelectMessage(item.id)} // Select or deselect message
                      />
                    </div>
                  )}
                </div>
              </div>{" "}
              <ul className="chat-msg-more">
                <li className="d-none d-sm-block">
                  <UncontrolledDropdown>
                    <DropdownToggle tag="a" href="#options" className="btn btn-icon btn-sm btn-trigger">
                      <Icon name="more-v" />
                    </DropdownToggle>
                    {/* Dropdown Menu */}
                    <DropdownMenu left>
                      {/* <DropdownItem
                      onClick={(ev) => {
                        ev.preventDefault();
                        onRemoveMessage(item.id);
                      }}
                      >
                        Delete
                      </DropdownItem> */}
                      <DropdownItem
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleMsgAction(item.id, "copy");
                        }}
                      >
                        Copy
                      </DropdownItem>
                      <DropdownItem
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleMsgAction(item.id, "reply");
                        }}
                      >
                        Reply
                      </DropdownItem>
                      {/* <DropdownItem
                        onClick={(ev) => {
                          ev.preventDefault();
                          console.log("Edit clicked");
                        }}
                      >
                        Edit
                      </DropdownItem> */}
                      <DropdownItem
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleMsgAction(item.id, "selectMsg");
                        }}
                      >
                        Select messages
                      </DropdownItem>
                      <DropdownItem
                        onClick={(ev) => {
                          ev.preventDefault();
                          handleMsgAction(item.id, "forwardMsg", item);
                        }}
                      >
                        Forward
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              </ul>
            </div>
          )}
          {/* );
          })} */}
        </div>
        <ul className="chat-meta">
          {/* <li>{chat.name}</li> */}
          <li>
            {new Date(item.timestamp.seconds * 1000).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </li>
        </ul>
      </div>
    </div>
  );
};

export const MetaChat = ({ item }) => {
  return (
    <div className="chat-sap">
      <div className="chat-sap-meta">
        <span>{item}</span>
      </div>
    </div>
  );
};

export const ChatItemHeader = ({ item }) => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/app-chat`} className="chat-link">
      {item.group === true ? (
        <div className="chat-media user-avatar user-avatar-multiple">
          {item.user.slice(0, 2).map((user, idx) => {
            return (
              <UserAvatar
                key={idx}
                theme={user.theme}
                text={findUpper(user.name)}
                image={user.image}
                className="chat-media"
              ></UserAvatar>
            );
          })}
          <span className={"status dot dot-lg dot-success"}></span>
        </div>
      ) : (
        <UserAvatar theme={item.theme} text={findUpper(item.name)} image={item.image} className="chat-media">
          <span className={`status dot dot-lg dot-${item.active === true ? "success" : "gray"}`}></span>
        </UserAvatar>
      )}
      <div className="chat-info">
        <div className="chat-from">
          <div className="name">{item.nickname ? item.nickname : item.name}</div>
          <span className="time">{item.date}</span>
        </div>
        <div className="chat-context">
          <div className="text">
            <p>{item.convo.length !== 0 && item.convo[item.convo.length - 1].chat[0]}</p>
          </div>
          <div className="status delivered">
            <Icon
              name={`${
                item.delivered === true ? "check-circle-fill" : item.delivered === "sent" ? "check-circle" : ""
              }`}
            ></Icon>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const ChatItem = ({ item, chatItemClick, setSelectedId, selectedId, currentUser, messages }) => {
  const { deleteConvo, propAction } = useContext(ChatContext);
  const checkBeforeDelete = (name, id) => {
    const dataId = `${name}_${id}`;
    console.log(selectedId);
    if (selectedId === id) {
      console.log(selectedId === id);
      setSelectedId(null);
    }
    // deleteConvo(dataId);
    openModal1(name, id);
  };
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const toggleModal1 = () => {
    setIsModalOpen1(!isModalOpen1);
  };

  const openModal1 = (name, id) => {
    setSelectedContact({ name, id });
    toggleModal1();
  };

  const confirmDelete1 = () => {
    if (selectedContact) {
      handleDeleteContact(selectedContact?.name, selectedContact?.id);
    }
    toggleModal1();
  };

  const handleDeleteContact = (name, id) => {
    removeChattingId(`${name}_${id}`, currentUser?.id);
    setSelectedId(null);
  };

  return (
    <>
      <li
        className={`chat-item ${item.unread ? "is-unread" : ""}`}
        style={item?.id === selectedId ? { backgroundColor: "#ebeef2" } : {}}
      >
        <a
          className="chat-link"
          href="#chat-link"
          onClick={(ev) => {
            ev.preventDefault();
            chatItemClick(item?.id, item?.username || item?.name, item);
          }}
        >
          {item?.profile_image_path || item?.profilePic ? (
            <img
              src={item?.profile_image_path || item?.profilePic} // URL of the DJ's profile image
              alt="Profile"
              className="user-avatar"
              style={{ width: "45px", height: "45px", borderRadius: "50%" }} // Ensure the image is rounded
            />
          ) : (
            <UserAvatar
              text={getInitiatals(item?.name || item?.username)}
              theme="primary"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            ></UserAvatar>
          )}
          <div className="chat-info ps-1">
            <div className="chat-from">
              <div className="name text-capitalize">
                {item?.name || item?.username} {item?.id === currentUser?.id ? "(You)" : ""}
              </div>
            </div>
            <span className="time" style={{ fontSize: "smaller" }}>
              {/* {currentUser?.createdAt} */}
            </span>
            <div className="chat-context">
              <div className="text">
                <ChatLastMessages itemId={item?.id} currentUserId={currentUser?.id} />{" "}
              </div>
              <div className="status delivered">
                <Icon
                  name={`${
                    item?.delivered === true ? "check-circle-fill" : item?.delivered === "sent" ? "check-circle" : ""
                  }`}
                ></Icon>
              </div>
            </div>
          </div>
        </a>
        <div className="chat-actions">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="btn btn-icon btn-sm btn-trigger dropdown-toggle">
              <Icon name="more-v"></Icon>
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li onClick={() => checkBeforeDelete(item?.username || item?.name, item?.id)}>
                  <DropdownItem
                    tag="a"
                    href="#delete"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    disabled={item?.id === currentUser?.id}
                  >
                    Delete
                  </DropdownItem>
                </li>
                {/* <li onClick={() => propAction(item.id, "unread")}>
                  <DropdownItem
                    tag="a"
                    href="#unread"
                    className={item.unread ? "disabled" : ""}
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    Mark as Unread
                  </DropdownItem>
                </li>
                <li onClick={() => propAction(item.id, "archive")}>
                  <DropdownItem
                    tag="a"
                    href="#archive"
                    className={item.archive ? "disabled" : ""}
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                  >
                    Archive Message
                  </DropdownItem>
                </li> */}
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </li>
      <ConfirmDeleteModal isOpen={isModalOpen1} toggle={toggleModal1} onConfirm={confirmDelete1} />
    </>
  );
};

export const ContactItem = ({ item, setTab, setSelectedId }) => {
  return (
    <ul className="contacts-list">
      <li>
        <h6 className="title overline-title-alt">{item?.contacts.length > 0 && item?.title}</h6>
      </li>
      {item.contacts.map((contact, idx) => {
        return (
          <li
            key={idx}
            onClick={() => {
              setTab("Chats");
              setSelectedId(contact.id);
            }}
          >
            {console.log(item)}{" "}
            <div className="user-card">
              <a href="#card" onClick={(ev) => ev.preventDefault()}>
                <UserAvatar text={findUpper(contact.name)} theme={contact.theme} image={contact.image}></UserAvatar>
                <div className="user-name">{contact.name}</div>
              </a>
              <div className="user-actions">
                <Link to={`${process.env.PUBLIC_URL}/app-chat`}>Start Chat</Link>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
