import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from "reactstrap";
import classnames from "classnames";
import { useNavigate, useParams } from "react-router";
import CardPay from "./CardPay";
import Paypal from "./Paypal";
import { Icon } from "../../../../components/Component";
import classNames from "classnames";
import { useQuery } from "react-query";
import { getUpcomingEventsList } from "../../../../http/getApi";
import PaymentDetails from "../EventDetails/PaymentDetails";
import { formatDate } from "../../../../utils/Utils";

const Payment = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [activeTab, setActivetab] = useState("1");
  const toggleIconTab = (tab) => {
    if (activeTab !== tab) {
      setActivetab(tab);
    }
  };

  //fetching event data from id
  const {
    data: upcomingEvent,
    isLoading: upcomingEventLoading,
    isError: upcomingEventsError,
  } = useQuery({
    queryKey: ["get-events-by-id", id],
    queryFn: () =>
      getUpcomingEventsList({ id: id, page: "", limit: "", search: "" }),
  });
  const contact_number =
    upcomingEvent?.data?.[0]?.client_details?.[0]?.work_phone_number;
  const packagePrice =
    upcomingEvent?.data?.[0]?.package_details?.[0]?.package_price;
  const retainerPrice =
    upcomingEvent?.data?.[0]?.event_financials?.retainer_value;
  const addOnTotal = upcomingEvent?.data?.[0]?.event_financials?.addon_total;
  const travelFee = upcomingEvent?.data[0]?.venue_details[0]?.travel_cost || 0;
  const discount1 = upcomingEvent?.data[0]?.event_financials?.discount1 || 0;
  const discount2 = upcomingEvent?.data[0]?.event_financials?.discount2 || 0;
  const balanceDueAfterRetainer =
    upcomingEvent?.data[0]?.event_financials?.balance_due_after_retainer || 0;
  const totalFee =
    balanceDueAfterRetainer +
    upcomingEvent?.data?.[0]?.event_financials?.retainer_value;
  const scheduledPayment2 =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_2;
  const scheduledPayment3 =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_3;
  const scheduledPayment2Date = formatDate(
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_2_txn
  );
  const scheduledPayment3Date = formatDate(
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_3_txn
  );
  const eventFinancials = upcomingEvent?.data?.[0]?.event_financials;

  return (
    <>
      <div className="mt-3 px-2">
        <h4>Make Your Payment</h4>
        <div class="card card-preview mt-3">
          <div className="card-inner pt-0">
            <Nav tabs>
              {/* <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "1" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <em class="icon ni ni-cc-alt-fill"></em>
                  <span>Payment Details</span>
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "1" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <em class="icon ni ni-paypal-alt"></em> <span>Paypal</span>
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "2" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("2");
                  }}
                >
                  <em class="icon ni ni-cc-alt-fill"></em> <span>Card</span>
                </NavLink>
              </NavItem> */}
            </Nav>

            <TabContent activeTab={activeTab}>
              {/* <TabPane tabId="1">
                <PaymentDetails
                  toggleMain={toggleIconTab}
                  mainCurrentTab={activeTab}
                  upcomingEvent={upcomingEvent}
                  packagePrice={packagePrice}
                  retainerPrice={retainerPrice}
                  addOnTotal={addOnTotal}
                  travelFee={travelFee}
                  discount1={discount1}
                  discount2={discount2}
                  balanceDueAfterRetainer={balanceDueAfterRetainer}
                  totalFee={totalFee}
                  scheduledPayment2={scheduledPayment2}
                  scheduledPayment3={scheduledPayment3}
                  scheduledPayment2Date={scheduledPayment2Date}
                  scheduledPayment3Date={scheduledPayment3Date}
                  eventFinancials={eventFinancials}
                />
              </TabPane> */}
              <TabPane tabId="1">
                <Paypal
                  toggleMain={toggleIconTab}
                  mainCurrentTab={activeTab}
                  id={id}
                  upcomingEvent={upcomingEvent}
                  contact_number={contact_number}
                />
              </TabPane>
              {/* <TabPane tabId="2">
                <CardPay
                  toggleMain={toggleIconTab}
                  mainCurrentTab={activeTab}
                />
              </TabPane> */}
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
