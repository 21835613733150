import React, { useEffect, useState } from "react";
import { Button, CardImg, CardTitle } from "reactstrap";
import {
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
} from "../../components/table/DataTable";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { formatDate, getCookie } from "../../utils/Utils";
import { getUpcomingEventsList } from "../../http/getApi";
import { useQuery } from "react-query";
import { ReactDataTable } from "../../components/Component";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const Overview = ({ allData, id }) => {
  const navigate = useNavigate();
  const [eventdata, setEventData] = useState([]);
  const [isEventPresent, setIsEventPresent] = useState(true);

  const {
    data: upcomingEventsList,
    isLoading: upcomingEventsLoading,
    isError: upcomingEventsListError,
  } = useQuery({
    queryKey: ["get-upcoming-events-list"],
    queryFn: () =>
      getUpcomingEventsList({
        id: "",
        page: "",
        limit: "",
        search: "",
      }),
  });
  // console.log(upcomingEventsList);

  const OpenEventDetails = (id) => {
    navigate(`/my-events/event-details/${id}`);
  };
  const DataTableData = [
    {
      id: 0,
      name: "Francine Kirby",
      age: 24,
      gender: "female",
      company: "BUZZWORKS",
      startDate: "2017-02-17",
      salary: "$2,570.39",
    },
    {
      id: 1,
      name: "Reva Best",
      age: 40,
      gender: "female",
      company: "MARQET",
      startDate: "2021-10-14",
      salary: "$1,488.76",
    },
    {
      id: 2,
      name: "Alexis Flores",
      age: 21,
      gender: "female",
      company: "OBONES",
      startDate: "2020-04-28",
      salary: "$1,336.93",
    },
    {
      id: 3,
      name: "Nixon Sullivan",
      age: 30,
      gender: "male",
      company: "SLUMBERIA",
      startDate: "2016-10-08",
      salary: "$2,156.70",
    },
    {
      id: 4,
      name: "Foreman Wooten",
      age: 20,
      gender: "male",
      company: "ESCENTA",
      startDate: "2018-07-12",
      salary: "$3,057.42",
    },
    {
      id: 5,
      name: "Franco Davis",
      age: 28,
      gender: "male",
      company: "ZILLACON",
      startDate: "2015-10-08",
      salary: "$2,730.88",
    },
  ];
  const dataTableColumns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Event Name",
      selector: (row) => row.event_name,
      sortable: true,
      hide: 370,
    },
    {
      name: "Event Type",
      selector: (row) => row.event_type_name,
      sortable: true,
      hide: "sm",
    },
    {
      name: "Event Date",
      selector: (row) => row.start_date_time,
      sortable: true,
      hide: "sm",
      cell: (row) => <span>{formatDate(row.start_date_time)}</span>,
    },
    {
      name: "Venue Name",
      selector: (row) => row.venue_details[0].address,
      sortable: true,
      hide: "md",
    },
    {
      name: "Details",
      selector: (row) => row.id,
      sortable: true,
      cell: (row) => (
        <Button
          className="text-white px-1 py-1 btnprimary"
          onClick={() => OpenEventDetails(row?.id)}
        >
          <span className="sub-text text-white">Event Details</span>
        </Button>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="border rounded">
        <div className="card-inner">
          <div className="card-title-group">
            <CardTitle>
              <h6 className="title">
                <h4 className="me-2">Upcoming events</h4>{""}
                {/* <Link
                  to={`${process.env.PUBLIC_URL}event-list`}
                  className="link d-block d-md-inline "
                >
                  History
                </Link> */}
              </h6>
            </CardTitle>
          </div>
        </div>
        {/* <DataTableBody className="border-top" bodyclass="nk-tb-orders">
          {upcomingEventsList?.data?.length !== 0 ? (
            <>
              <DataTableHead>
                <DataTableRow>
                  <span className="text-dark">Sr. No.</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="text-dark">Event Name</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="text-dark">Event Type</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="text-dark">Event Date</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="text-dark">Venue Name</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="text-dark">Details</span>
                </DataTableRow>
              </DataTableHead>
              {Array.isArray(upcomingEventsList?.data) &&
                upcomingEventsList?.data?.map((item, idx) => {
                  return (
                    <DataTableItem key={idx}>
                      <DataTableRow>
                        <span className="tb-lead">
                          <a href="#order" className="text-dark">
                            {idx + 1}
                          </a>
                        </span>
                      </DataTableRow>

                      <DataTableRow>
                        <span className="tb-sub text-dark">
                          {item?.event_name}
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="tb-sub text-dark">
                          {item?.event_type_name}
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="tb-sub tb-amount text-dark">
                          {formatDate(item?.start_date_time)}
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="tb-sub tb-amount text-dark">
                          {item?.venue_details[0].address}
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <Button
                          className="text-white px-1 py-1 btnprimary"
                          onClick={() => OpenEventDetails(item?.id)}
                        >
                          <span className="sub-text text-white">
                            Event Details
                          </span>
                        </Button>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })}
            </>
          ) : (
            <div className="text-center">
              <h3 className="mb-4">No Upcoming Events Scheduled</h3>
              <p className="lead mb-4">
                Hey it looks like there are no upcoming events scheduled at the
                moment.
              </p>
              <p className="mb-4">
                Don't worry, we're here to help! You can start planning your
                next event with us.
              </p>
              <Button color="primary">Plan Your Event</Button>
            </div>
          )}
        </DataTableBody> */}
        {upcomingEventsList && (
          <div className="p-2">
            <ReactDataTable
              data={upcomingEventsList.data}
              columns={dataTableColumns}
              expandableRows
              // pagination
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default Overview;
