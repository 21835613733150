import React from "react";
import { Button } from "reactstrap";
import Contactus from "../../assets/images/ContactImage.png";
import Required from "../../components/Required";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from "react";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Contact = () => {
  const recaptcha = useRef();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    console.log("captcha token :", token);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required.";
    } else if (/\d/.test(formData.name)) {
      newErrors.name = "Name should not contain numbers.";
    } else if (/[^a-zA-Z\s]/.test(formData.name)) {
      newErrors.name = "Name should not contain special characters.";
    }

    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }

    if (!formData.subject) {
      newErrors.subject = "Subject is required.";
    } else if (/\d/.test(formData.subject)) {
      newErrors.subject = "Subject should not contain numbers.";
    }
    if (!formData.message) {
      newErrors.message = "Message is required.";
    } else if (formData.message.length < 10) {
      newErrors.message = "Message must be at least 10 characters.";
    }

    if (!recaptchaToken) {
      newErrors.recaptcha = "Please verify the reCAPTCHA.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  function handleSubmit(event) {
    event.preventDefault();
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
      return;
    }

    toast.success("Form Data Submited Successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }
  return (
    <div className="contact m-2 border">
      <div className="container bg-white">
        <div className="row">
          <div className="col-lg-6">
            <h3 className="text-center" style={{ marginTop: "50px" }}>
              Contact Us
            </h3>
            <img src={Contactus} className="img-fluid" alt="Contact Us" />
          </div>
          <div className="col-lg-6 mt-5">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 w-75">
                <label htmlFor="name" className="form-label">
                  Name:
                  <Required />
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter Your Name."
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && <p className="text-danger">{errors.name}</p>}
              </div>
              <div className="mb-3 w-75">
                <label htmlFor="email" className="form-label">
                  Email:
                  <Required />
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="text-danger">{errors.email}</p>}
              </div>
              <div className="mb-3 w-75">
                <label htmlFor="subject" className="form-label">
                  Subject:
                  <Required />
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                />
                {errors.subject && (
                  <p className="text-danger">{errors.subject}</p>
                )}
              </div>
              <div className="mb-3 w-75">
                <label htmlFor="message" className="form-label">
                  Message:
                  <Required />
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  placeholder="Write your notes"
                  value={formData.message}
                  onChange={handleChange}
                  rows="3"
                />
                {errors.message && (
                  <p className="text-danger">{errors.message}</p>
                )}
              </div>
              <div className="md:flex md:items-center mx-auto">
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={
                    process.env.REACT_APP_CAPTCHA_GOOGLE_CAPTCHA_SITE_KEY
                  }
                  onChange={handleRecaptchaChange}
                />
                {errors.recaptcha && (
                  <p className="text-danger">{errors.recaptcha}</p>
                )}
                <Button
                  color="primary"
                  type="submit"
                  className="mt-2 mb-2 btnprimary"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
  