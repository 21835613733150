import React from "react";
import { Card, CardBody, CardText } from "reactstrap";
import { Icon, UserAvatar } from "../../components/Component";
import { findUpper } from "../../utils/Utils";

const ContactCard = ({ contact, chatItemClick, item }) => {
  const { id, name, profilePic } = contact;

  return (
    <Card className="text-center" style={{ width: "13rem", backgroundColor: "#e0e7ef", borderRadius: "8px" }}>
      <CardBody>
        {item?.forwarded && (
          <div className="text-50 opacity-75 text-start">
            <Icon name="reply-all"></Icon>
            forwarded
            <hr style={{ width: "100%", margin: "5px auto", color: "grey" }} />
          </div>
        )}
        {/* Profile Picture */}
        <div className="d-flex justify-content-center mb-2">
          <UserAvatar image={profilePic} size="lg" text={findUpper(name)} />
        </div>

        {/* Contact Name */}
        <CardText tag="h5" className="mb-2">
          {name}
        </CardText>

        {/* Horizontal Divider */}
        <hr style={{ width: "100%", margin: "0 auto", color: "grey" }} />

        {/* Label (e.g., "Chat") */}
        <CardText
          className="mt-2 text-muted"
          style={{ fontSize: "1rem", cursor: "pointer" }}
          onClick={() => chatItemClick(id, name, contact)}
        >
          Chat
        </CardText>
      </CardBody>
    </Card>
  );
};
export default ContactCard;
