import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Input, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { db } from "../../Firebase Files/firebaseConfig";
import { addDoc, collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { UserAvatar } from "../../components/Component";
import { CommonToaster, findUpper } from "../../utils/Utils";
import { postRequest } from "../../api-service";

const ShareContactModal = ({ isOpen, toggle, shareContact, currentUser }) => {
  const [filterText, setFilterText] = useState("");
  const [contact, setContact] = useState([]);
  const [firebaseContact, setFirebaseContact] = useState([]);
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [sentMessages, setSentMessages] = useState([]); // Tracks sent messages
  const [loading, setLoading] = useState(null);
  const [showToast, setShowToast] = useState(false);

  const toggleToast = () => {
    setShowToast(!showToast); // Toggle the showToast state
  };

  // Function to fetch users from your API
  const getIpdmUsers = async () => {
    const formData = new FormData();
    formData.append("limit", 20);
    formData.append("search", filterText);

    try {
      const res = await postRequest("api/v1/user/users-list", formData);

      if (res && res.data?.users && filterText) {
        setContact((prevContacts) => [...prevContacts, ...res.data?.users]);
      } else {
        setContact(contact);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    getIpdmUsers();
  }, [filterText]);

  // Firebase real-time listener for Users collection
  useEffect(() => {
    const getUsers = () => {
      const chatCollection = collection(db, "Users");

      const unsubscribe = onSnapshot(
        chatCollection,
        (snapshot) => {
          const chatList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const sortedChatList = chatList.filter((user) => user.id !== shareContact?.id).sort((a, b) => 0);

          setContact(sortedChatList);
          setFirebaseContact(sortedChatList);
        },
        (error) => {
          console.error("Error fetching chat data: ", error);
        }
      );

      return () => unsubscribe();
    };

    getUsers();
  }, [shareContact]);

  // Filtering users by search text
  useEffect(() => {
    if (filterText !== "") {
      const filteredObject = contact.filter((item) => {
        return (
          item.name?.toLowerCase().includes(filterText.toLowerCase()) ||
          item.username?.toLowerCase().includes(filterText.toLowerCase())
        );
      });

      const uniqueFilteredObject = filteredObject.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );

      setFilteredChatList([...uniqueFilteredObject]);
    } else {
      const uniqueContactList = contact.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );

      setFilteredChatList([...uniqueContactList]);
      setContact(firebaseContact);
    }
  }, [filterText, contact, firebaseContact]);

  // Reset sentMessages when modal closes
  useEffect(() => {
    if (!isOpen) {
      setSentMessages([]);
    }
  }, [isOpen]);

  // Send a new message
  const handleSendMessage1 = async (id) => {
    setLoading(id);

    // Dynamically create or fetch the chat for the selected user
    const chatId = await createNewChat(id);

    if (chatId && currentUser) {
      const messagesRef = collection(db, "Chats", chatId, "messages");
      try {
        await addDoc(messagesRef, {
          contactShare: true,
          text: "",
          contactInfo: {
            createdAt: shareContact.createdAt,
            id: shareContact.id,
            name: shareContact.name,
            profilePic: shareContact.profilePic,
          },
          senderId: currentUser.id,
          senderName: currentUser.name,

          timestamp: new Date(),
        });

        // Mark this user as having received the message
        setSentMessages((prev) => [...prev, id]);
        setLoading(null);
        toggleToast();
      } catch (error) {
        setLoading(null);

        console.error("Error sending message:", error);
      }
    }
  };

  const createNewChat = async (id) => {
    const currentUserId = currentUser?.id;
    if (!currentUserId) {
      console.error("No user logged in");
      return null; // Ensure a consistent return type
    }

    const q = query(collection(db, "Chats"), where("participants", "array-contains", currentUserId));
    const querySnapshot = await getDocs(q);
    let chatId = null;

    querySnapshot.forEach((doc) => {
      const participants = doc.data().participants;
      if (participants.includes(id)) {
        chatId = doc.id; // Chat already exists
        selectUser({ chatId, id }); // Update selected user
      }
    });

    if (!chatId) {
      try {
        const newChat = await addDoc(collection(db, "Chats"), {
          participants: [currentUserId, id],
          createdAt: new Date(),
        });
        chatId = newChat.id; // Newly created chat ID
        selectUser({ chatId, id }); // Update selected user
      } catch (error) {
        console.error("Error creating new chat:", error);
      }
    }

    return chatId; // Return the chat ID for further use
  };

  const selectUser = (user) => {
    setSelectedUser(user); // Set the currently selected user
    setUsers(users.map((u) => (u.id === user.id ? { ...u, selected: true } : { ...u, selected: false }))); // Mark user as selected
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} style={{ backgroundColor: "#1f2937", color: "#fff" }}>
          Forward Message
        </ModalHeader>
        <ModalBody>
          {/* Search Input */}
          <Input
            type="text"
            placeholder="Search Contacts..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{ marginBottom: "5px" }}
            />

          {/* Contact List */}
          <ListGroup flush style={{ height: 400, overflowY: "auto" }}>
            {filteredChatList.map((user) => (
              <ListGroupItem key={user?.id} className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">             <UserAvatar
                    image={user?.profilePic || user?.profile_image_path}
                    size="md"
                    text={findUpper(user?.name || user?.username)}
                  />
                  <span style={{ marginLeft: "10px" }}>{user?.name || user?.username}</span>
                </div>
                <Button
                  color="secondary"
                  onClick={() => handleSendMessage1(user?.id)}
                  disabled={sentMessages.includes(user.id)} // Disable if already sent
                >
                  {loading === user.id ? <Spinner size={"sm"} /> : sentMessages.includes(user.id) ? "Sent" : "Send"}
                </Button>
              </ListGroupItem>
            ))}
          </ListGroup>
          {/* Render the CommonToaster */}
          <CommonToaster
            showToast={showToast}
            message={"Sent Successfully.."} // Pass the dynamic message to the toast
            toggleToast={toggleToast}
            theme={"green"}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ShareContactModal;
