import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { Icon } from "../../components/Component";
import excel from "../../assets/images/Excl.png";
import word from "../../assets/images/word file.png";
import music from "../../assets/images/Music.png";
import zip from "../../assets/images/Zip.png";
import doc from "../../assets/images/doc.png";

function FileMessage({ item, pdfImg }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const MAX_MEDIA_DISPLAY = 4;

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleMediaClick = (index) => {
    setCurrentMediaIndex(index);
    toggleModal();
  };

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const link = document.createElement("a");
      const objectUrl = URL.createObjectURL(blob);
      const extension = url.split(".").pop().split("?")[0];
      const filename = `downloaded-file.${extension}`;

      link.href = objectUrl;
      link.download = filename;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };

  // Filter media and documents
  const mediaFiles = item.fileURLs.filter((url) => {
    const fileType = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)?.[1];
    return ["jpg", "png", "jpeg", "gif", "avif", "mp4", "webm", "ogg"].includes(fileType);
  });

  const documentFiles = item.fileURLs.filter((url) => {
    const fileType = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)?.[1];
    return !["jpg", "png", "jpeg", "gif", "avif", "mp4", "webm", "ogg"].includes(fileType);
  });

  const getIconForFileType = (fileType) => {
    switch (fileType) {
      case "pdf":
        return pdfImg;
      case "doc":
      case "docx":
        return word;
      case "zip":
      case "rar":
        return zip;
      case "mp3":
      case "mp4":
        return music;
      case "xlsx":
        return excel;
      default:
        return doc;
    }
  };

  return (
    <div className="message-files">
      {/* Display Images and Videos */}
      {(mediaFiles?.length > 0 || documentFiles?.length > 0) && (
        <>
          <div className={`${mediaFiles?.length === 1 ? "" : "media-grid"}`}>
            {mediaFiles.slice(0, MAX_MEDIA_DISPLAY).map((url, index) => {
              const fileType = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)?.[1];
              const isImage = ["jpg", "png", "jpeg", "gif", "avif"].includes(fileType);
              const remainingCount = mediaFiles.length - MAX_MEDIA_DISPLAY;

              return (
                <div
                  className="media-item"
                  key={index}
                  onClick={() => handleMediaClick(index)}
                  style={{ position: "relative" }}
                >
                  {isImage ? (
                    <img
                      src={url}
                      alt="file"
                      style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 10 }}
                    />
                  ) : (
                    <video
                      src={url}
                      style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 10 }}
                      muted
                    />
                  )}
                  {index === MAX_MEDIA_DISPLAY - 1 && remainingCount > 0 && (
                    <div
                      className="more-overlay"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "1.5rem",
                        borderRadius: 10,
                      }}
                    >
                      +{remainingCount} more
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* Display Documents */}
          <div className="document-section">
            {documentFiles.map((url, index) => {
              const fileType = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)?.[1];
              // Extract the file name from the URL
              const fullFileName = url.split("%").pop().split("?")[0]; // Get the base file name without query params
              const fileExtension = fullFileName.split(".").pop(); // Get the file extension
              const baseName = fullFileName.replace(/\.[^/.]+$/, ""); // Remove the extension to get the base name
              const truncatedFileName = `${baseName.slice(-20)}.${fileExtension}`; // Take the last 10 chars of base name and add the extension

              return (
                <div key={index} className="document-link">
                  <div className="document-preview">
                    <img src={getIconForFileType(fileType)} alt="document" className="document-icon" />
                    <div className="document-info">
                      <span className="document-name text-danger">{truncatedFileName}</span>
                      <span className="document-download pointer" onClick={() => handleDownload(url)}>
                        Download
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Modal for Image/Video Display */}
          <Modal isOpen={isModalOpen} toggle={toggleModal} centered size="lg" className="media-modal">
            <ModalHeader
              toggle={toggleModal}
              className="modal-header-custom d-flex justify-content-between modal-header-content"
            >
              <span>Media Gallery</span>
            </ModalHeader>
            <ModalBody className="media-modal-body">
              <Button
                color="primary"
                onClick={() => handleDownload(mediaFiles[currentMediaIndex])}
                className="download-button"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 10,
                  height: "fit-content",
                }}
              >
                <Icon name={"download"} />
              </Button>
              <div className="media-container">
                {mediaFiles.map((url, index) => {
                  const fileType = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)?.[1];
                  const isImage = ["jpg", "png", "jpeg", "gif", "avif"].includes(fileType);

                  if (index === currentMediaIndex) {
                    return (
                      <div key={index} className="media-content">
                        {isImage ? (
                          <img src={url} alt="media" className="media-display" />
                        ) : (
                          <video src={url} controls className="media-display" />
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>

              {/* Navigation Buttons */}
              <div className="navigation-buttons">
                <Button
                  color="secondary"
                  className="nav-button left"
                  onClick={() => setCurrentMediaIndex((prev) => prev - 1)}
                  disabled={currentMediaIndex === 0}
                >
                  &#8249;
                </Button>
                <Button
                  color="secondary"
                  className="nav-button right"
                  onClick={() => setCurrentMediaIndex((prev) => prev + 1)}
                  disabled={currentMediaIndex === mediaFiles.length - 1}
                >
                  &#8250;
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </div>
  );
}

export default FileMessage;
