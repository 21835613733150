import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Head from "./head/Head";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import "../css/style.css";
import AppHeader from "./header/AppHeader";
import Cookies from "js-cookie";
import { Sidebar } from "../components/Component";

const Layout = ({ title, app, ...props }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    // let at = Cookies.get("authToken");
    // if (!at) {
    //   window.location = "/";
    // }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const body = document.getElementsByClassName("nk-sidebar")[0];
  //   const bodyMain = document.querySelector("body");
  //   if (body && location.pathname === "/chat") {
  //     body.classList.add("nk-sidebar-mobile");
  //     bodyMain.classList.add("nav-shown");
  //     console.log("In chat section");
  //   } else {
  //     body.classList.remove("nk-sidebar-mobile");
  //     bodyMain.classList.remove("nav-shown");
  //   }
  //   console.log("In useEffect");
  // }, [location]);

  return (
    <>
      <Head title={!title && "Loading"} />
      <AppRoot>
        {/* <Appbar /> */}
        <AppMain>
          <Sidebar fixed />
          <AppWrap>
            <section>
              <AppHeader app={app} fixed />
              <Outlet />
            </section>
          </AppWrap>
        </AppMain>
      </AppRoot>
    </>
  );
};
export default Layout;
