import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Tooltip,
} from "reactstrap";
import { FaStar } from "react-icons/fa";

const Review = () => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [message, setMessage] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log("Rating:", rating);
    console.log("Message:", message);
    setRating(0);
    setMessage("");
  };

  return (
    <div>
      <h5 className="mb-4">Add Your Review</h5>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="rating" className="d-block">
            Rating
          </Label>
          <div className="mb-3">
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              return (
                <label key={index} className="me-1">
                  <Input
                    type="radio"
                    name="rating"
                    value={ratingValue}
                    onClick={() => setRating(ratingValue)}
                    style={{ display: "none" }}
                  />
                  <FaStar
                    className="star"
                    color={
                      ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"
                    }
                    size={40}
                    onMouseEnter={() => setHover(ratingValue)}
                    onMouseLeave={() => setHover(0)}
                    id={`star-${ratingValue}`}
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target={`star-${ratingValue}`}
                    toggle={toggleTooltip}
                  >
                    {ratingValue} Star{ratingValue > 1 ? "s" : ""}
                  </Tooltip>
                </label>
              );
            })}
          </div>
          <FormText color="muted">
            Please select a rating from 1 to 5 stars.
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label for="message">Message</Label>
          <Input
            type="textarea"
            name="message"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
          />
          <FormText color="muted">
            Share your experience or provide feedback.
          </FormText>
        </FormGroup>
        <Button color="primary" type="submit" className="btnprimary">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default Review;
