import React, { useContext, useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown, Spinner } from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import { LinkList, LinkItem } from "../../components/links/Links";
import UserAvatar from "../../components/user/UserAvatar";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import { getCookie, getInitiatals } from "../../utils/Utils";
import Cookies from "js-cookie";
import profileimg from "../../images/Party DJ Mix.png";
import { useQuery } from "react-query";
import { getClientData } from "../../http/getApi";

const User = () => {
  const [profileName, setProfileName] = useState(getCookie("username", "John"));
  // const { userProfile } = useContext(DataContext);
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };
  const setLogout = () => {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("logoutURL", process.env.REACT_APP_ACCOUNT_URL, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    Cookies.set("dashboard", "client", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };

  const {
    data: clientData,
    isLoading: clientDataLoading,
    isError: clientDataError,
  } = useQuery({
    queryKey: ["get-client-id"],
    queryFn: () => getClientData(),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle nk-quick-nav-icon"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
      <div style={{ width: "40px", height: "40px" }}>
          {clientDataLoading ? (
            <div className="" style={{ height: "25px" }}>
              <Spinner style={{ height: "40px", width: "40px" }} color="light" />
              <div className="top-0 position-absolute z-n1 h-75 w-75">
                <UserAvatar icon="user-alt" theme="primary" />
              </div>
            </div>
          ) : clientDataError ? (
            <div className="h-75 w-75" id="user">
              <UserAvatar icon="alert-circle" theme="danger" className="fs-3" />
              <TooltipComponent id="user" text={"Something went wrong!!!"} direction="left" />
            </div>
          ) : clientData ? (
            <img src={clientData} alt="dj" className="object-fit-cover" style={{ borderRadius: "50%",height:"40px",width:"40px"}}/>
          ) : (
            <UserAvatar text={getInitiatals(profileName)} theme="primary" className="fs-7 h-100 " />
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter ">
          <div className="user-card sm">
            <div className="user-info px-2">
            <div style={{ width: "40px", height: "40px" }}>
          {clientDataLoading ? (
            <div className="" style={{ height: "25px" }}>
              <Spinner style={{ height: "40px", width: "40px" }} color="light" />
              <div className="top-0 position-absolute z-n1 h-75 w-75">
                <UserAvatar icon="user-alt" theme="primary" />
              </div>
            </div>
          ) : clientDataError ? (
            <div className="h-75 w-75" id="user">
              <UserAvatar icon="alert-circle" theme="danger" className="fs-3" />
              <TooltipComponent id="user" text={"Something went wrong!!!"} direction="left" />
            </div>
          ) : clientData ? (
            <img src={clientData} alt="dj" className="object-fit-cover" style={{ borderRadius: "50%",height:"35px",width:"35px"}}/>
          ) : (
            <UserAvatar text={getInitiatals(profileName)} theme="primary" className="fs-7 h-100 " />
          )}
        </div>
            </div>
            <div className="user-info">
              <span className="lead-text">{getCookie("username", "John")}</span>
              <span className="sub-text">
                {getCookie("email", "mailto:john@silocloud.io")}
              </span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            {/* <LinkItem link="/" icon="user-alt" onClick={toggle}>
              View Profile
            </LinkItem> */}
            {/* <LinkItem
              link={`${process.env.REACT_APP_ACCOUNT_URL}user-profile-regular`}
              icon="setting-alt"
              onClick={toggle}
            >
              Account Setting
            </LinkItem> */}
            {/* <LinkItem link="/" icon="activity-alt" onClick={toggle}>
              Login Activity
            </LinkItem> */}
            <li>
              <a
                className={`dark-switch ${
                  theme.skin === "dark" ? "active" : ""
                }`}
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
                }}
              >
                {theme.skin === "dark" ? (
                  <>
                    <em className="icon ni ni-sun"></em>
                    <span>Light Mode</span>
                  </>
                ) : (
                  <>
                    <em className="icon ni ni-moon"></em>
                    <span>Dark Mode</span>
                  </>
                )}
              </a>
            </li>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="#" icon="signout" onClick={setLogout}>
              Sign Out
            </LinkItem>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
