import React, { useState, createContext, useEffect } from "react";
import { chatData, addUserData } from "./ChatData";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../Firebase Files/firebaseConfig";

export const ChatContext = createContext();

export const ChatContextProvider = (props) => {
  const [contact, setContact] = useState([]);
  const [chat, setChat] = useState([]); // Initialize chat as an empty array

  // console.log("contacts", contact);

  useEffect(() => {
    const getUsers = () => {
      const chatCollection = collection(db, "Users");

      // Real-time listener for Users collection
      const unsubscribe = onSnapshot(
        chatCollection,
        (snapshot) => {
          const chatList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setContact(chatList); // Set the contact state with Firestore data
          // console.log("chatList", chatList);
        },
        (error) => {
          console.error("Error fetching chat data: ", error);
        }
      );

      // Cleanup listener on unmount
      return () => unsubscribe();
    };

    getUsers();
  }, []);

  // Sync chat state whenever contact is updated
  useEffect(() => {
    setChat(contact); // Ensure chat state is updated with contact
  }, [contact]);

  const [favData, setFavData] = useState(chatData);
  const [userData, setUserData] = useState(addUserData);

  // Function for deleting a conversation
  const deleteConvo = (id) => {
    console.log(chat);
    console.log(id);
    let data = chat;
    data = data.filter((item) => item.id !== id);
    setChat([...data]);
    console.log(chat);
  };

  // Universal function for any props
  const propAction = (id, prop) => {
    let data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index][prop] = true;
    setChat([...data]);
  };

  // Function to add favourite to favourite list
  const favAction = (id) => {
    let data = favData;
    const index = data.findIndex((item) => item.id === id);
    data[index].fav = true;
    setFavData([...data]);
    setChat([...data]);
  };

  // Function to change nickname
  const changeNickname = (id, name) => {
    const data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index].nickname = name;
    setChat([...data]);
  };

  // Function to change theme
  const changeTheme = (id, name) => {
    const data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index].chatTheme = name;
    setChat([...data]);
  };

  // Removes user from group
  const deleteUser = (id, userId) => {
    let data = chat;
    const grouped = data.findIndex((item) => item.id === id);
    const group = data.find((item) => item.id === id);
    const user = group.user.filter((user) => user.id !== userId);
    group.user = user;
    data[grouped] = group;
    setChat([...data]);
  };

  // Makes a user admin
  const makeAdmin = (id, userId) => {
    let data = chat;
    const grouped = data.findIndex((item) => item.id === id);
    const group = data.find((item) => item.id === id);
    group.user.forEach((element) => {
      element.role = "User";
    });
    const userIndex = group.user.findIndex((item) => item.id === userId);
    group.user[userIndex].role = "Admin";
    data[grouped] = group;
    setChat([...data]);
  };

  // Add a user to a chat
  const addUserToChat = (id, object) => {
    let data = chat;
    const group = data.find((item) => item.id === id);
    let newUserGroup = [...group.user, object];
    group.user = newUserGroup;
    setChat(data);
    let newUserData = userData;
    let user = newUserData.filter((el) => el.id !== object.id);
    setUserData(user);
  };

  return (
    <ChatContext.Provider
      value={{
        chatState: [chat, setChat],
        chatData: chat,
        fav: [favData],
        userData: userData,
        deleteConvo: deleteConvo,
        propAction: propAction,
        favAction: favAction,
        changeNickname: changeNickname,
        changeTheme: changeTheme,
        deleteUser: deleteUser,
        makeAdmin: makeAdmin,
        addUserToChat: addUserToChat,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};
