import React, { useEffect, useState, useContext } from "react";
import Head from "../../layout/head/Head";
import ContentAlt from "../../layout/content/ContentAlt";
import AppContact from "./contact/Contact";
import ChatBody from "./ChatBody";
import User from "../../images/avatar/b-sm.jpg";
import { Button, Icon, UserAvatar } from "../../components/Component";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  ModalFooter,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { chatData } from "./ChatData";
import { ChatContext } from "./ChatContext";
import { Link } from "react-router-dom";
import { ChannelAsideBody, ChatAsideBody } from "./ChatAsideBody";
import Cookies from "js-cookie";
import { db } from "../../Firebase Files/firebaseConfig";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { postRequest, postRequestGetUserProfile } from "../../api-service";
import {
  updateUserStatus,
  updateUserVisibility,
} from "../../Firebase Files/firebase-service";
import homePageImg from "../../assets/images/Chat Graphics.png";
import { getInitiatals } from "../../utils/Utils";
import { useThemeUpdate } from "../../layout/provider/Theme";
const Chat = () => {
  const [mainTab, setMainTab] = useState("Chats");
  const [selectedId, setSelectedId] = useState();
  const [selectedName, setSelectedName] = useState("");

  const [selectedUsername, setSelectedUsername] = useState("");

  const [filterTab, setFilterTab] = useState("messages");
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [favState, setFavState] = useState(false);
  const [favFilter, setFavFilter] = useState([]);
  const [favFilterText, setFavFilterText] = useState("");
  const [mobileView, setMobileView] = useState(false);
  // console.log("filteredChatList from chat", filteredChatList);

  const { chatState, fav } = useContext(ChatContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [profile, setProfile] = useState(null); // State for profile
  const authToken = Cookies.get("authToken");
  const [currentUserAvatar, setCurrentUserAvatar] = useState("");

  const [contact, setContact] = useState([]);
  const [FireBaseContact, setFirebaseContact] = useState([]);
  const [modal, setModal] = useState(false);
  const [textMsg, setTextMsg] = useState("");

  const [messages, setMessages] = useState([]);
  const toggleModal = () => setModal(!modal);

  // console.log("contacts", contact);

  // const themeUpdate = useThemeUpdate();

  // useEffect(() => {
  //   console.log("HI");
  //   console.log(themeUpdate);
  //   themeUpdate.sidebarMobile();
  //   return () => {
  //     console.log("BYE");
  //   };
  // }, []);

  useEffect(() => {
    createNewChat(currentUser?.id);
  }, [currentUser?.id, currentUser]);

  const createNewChat = async (id) => {
    const currentUserId = currentUser?.id;
    if (!currentUserId) {
      console.error("No user logged in");
      return;
    }

    const q = query(
      collection(db, "Chats"),
      where("participants", "array-contains", currentUserId)
    );

    const querySnapshot = await getDocs(q);
    let chatExists = false;

    querySnapshot.forEach((doc) => {
      const participants = doc.data().participants;
      if (participants.includes(id)) {
        chatExists = true;
        selectUser({ chatId: doc.id, ...id });
      }
    });

    if (!chatExists) {
      try {
        const newChat = await addDoc(collection(db, "Chats"), {
          participants: [currentUserId, id],
          createdAt: new Date(),
        });
        selectUser({ chatId: newChat.id, ...id });
      } catch (error) {
        console.error("Error creating new chat:", error);
      }
    }
  };

  const checkChatAvailable = async (id) => {
    // console.log("chat not exists id", id);

    const currentUserId = currentUser?.id;
    if (!currentUserId) {
      console.error("No user logged in");
      return;
    }

    const q = query(
      collection(db, "Chats"),
      where("participants", "array-contains", currentUserId)
    );

    const querySnapshot = await getDocs(q);
    let chatExists = false;

    querySnapshot.forEach((doc) => {
      const participants = doc.data().participants;
      if (participants.includes(id)) {
        chatExists = true;
        selectUser({ chatId: doc.id, ...id });
      }
    });

    if (!chatExists) {
      console.log("chat not exists");
      if (id !== currentUser?.id) {
        toggleModal();
      }
    }
  };

  const selectUser = (user) => {
    setSelectedUser(user);
    setUsers(
      users.map((u) =>
        u.id === user.id ? { ...u, selected: true } : { ...u, selected: false }
      )
    );
  };
  const [chat, setChat] = chatState;
  const [favData] = fav;

  useEffect(() => {
    const getUsers = async () => {
      const userCollectionRef = collection(db, "Users");

      // Function to filter and sort users based on conditions
      const filterAndSortUsers = async (userList) => {
        if (!currentUser || !currentUser.id) {
          console.warn("currentUser or currentUser.id is undefined");
          return [];
        }

        const filteredUserPromises = userList.map(async (user) => {
          // Check if the user has currentUser.id in their chattingIds
          if (user.chattingIds?.includes(currentUser.id)) {
            const q = query(
              collection(db, "Chats"),
              where("participants", "array-contains", currentUser.id)
            );
            const querySnapshot = await getDocs(q);

            const isChatParticipant = querySnapshot.docs.some((chatDoc) => {
              const participants = chatDoc.data().participants;
              return participants.includes(user.id);
            });

            return isChatParticipant ? user : null;
          }
          return null;
        });

        const filteredUserList = (
          await Promise.all(filteredUserPromises)
        ).filter(Boolean);
        // Sort the list, placing the current user at the top
        const sortedUserList = filteredUserList.sort((a, b) => {
          if (a.id === currentUser.id) return -1;
          if (b.id === currentUser.id) return 1;
          return 0;
        });
        return sortedUserList;
      };

      // Real-time listener for Users collection
      const unsubscribeUsers = onSnapshot(
        userCollectionRef,
        async (snapshot) => {
          const userList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const sortedUserList = await filterAndSortUsers(userList);
          setContact(sortedUserList);
          setFirebaseContact(sortedUserList);
        },
        (error) => {
          console.error("Error fetching user data: ", error);
        }
      );

      // Real-time listener for incoming messages
      const unsubscribeMessages = onSnapshot(
        collection(db, "Messages"),
        async (snapshot) => {
          if (!currentUser || !currentUser.id) {
            return;
          }

          const incomingMessages = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const usersWhoMessaged = incomingMessages
            .filter((msg) => msg.to === currentUser.id)
            .map((msg) => msg.from);

          const uniqueUserIds = [...new Set(usersWhoMessaged)];
          const newUsersPromises = uniqueUserIds.map(async (userId) => {
            const userDoc = await getDoc(doc(userCollectionRef, userId));
            return userDoc.exists() ? { id: userId, ...userDoc.data() } : null;
          });

          const newUsers = (await Promise.all(newUsersPromises)).filter(
            Boolean
          );

          // Combine with existing contacts and remove duplicates
          const existingContacts = await filterAndSortUsers(
            snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          );

          // Combine contacts while ensuring uniqueness
          const combinedContacts = [
            ...new Map(
              [...existingContacts, ...newUsers].map((user) => [user.id, user])
            ).values(),
          ];

          // Sort contacts to place the current user at the top
          const sortedContacts = combinedContacts.sort((a, b) => {
            if (a.id === currentUser.id) return -1; // Current user on top
            if (b.id === currentUser.id) return 1; // Ensure current user comes before others
            return 0; // Maintain original order for other users
          });

          setContact(sortedContacts);
          setFirebaseContact(sortedContacts);
        },
        (error) => {
          console.error("Error fetching message data: ", error);
        }
      );

      // Cleanup listeners on unmount
      return () => {
        unsubscribeUsers();
        unsubscribeMessages();
      };
    };

    getUsers();
  }, [currentUser, textMsg]);
  const [sharedPhotos, setSharedPhotos] = useState([]);

  useEffect(() => {
    if (selectedUser?.chatId) {
      const messagesRef = collection(
        db,
        "Chats",
        selectedUser.chatId,
        "messages"
      );
      const q = query(messagesRef, orderBy("timestamp", "asc"));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(updatedMessages);

        // Extract fileURLs from messages
        const allFileURLs = updatedMessages
          .filter((msg) => Array.isArray(msg.fileURLs)) // Ensure fileURLs exists and is an array
          .flatMap((msg) => msg.fileURLs); // Flatten all URLs into a single array

        setSharedPhotos(allFileURLs); // Update sharedPhotos state
      });

      return () => unsubscribe();
    }
  }, [selectedUser?.chatId, selectedId]);

  // Filtering users by search and removing duplicate users based on their IDs
  useEffect(() => {
    if (filterText !== "") {
      const filteredObject = contact.filter((item) => {
        return (
          item.name?.toLowerCase().includes(filterText.toLowerCase()) ||
          item.username?.toLowerCase().includes(filterText.toLowerCase())
        );
      });

      // Remove duplicates based on user ID
      const uniqueFilteredObject = filteredObject.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );

      setFilteredChatList([...uniqueFilteredObject]);
    } else {
      // Remove duplicates when displaying the full contact list
      const uniqueContactList = contact.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      );

      setFilteredChatList([...uniqueContactList]);
      setContact(FireBaseContact);
    }
  }, [filterText, setFilteredChatList, contact, messages]);

  // Filtering favourite users by search
  useEffect(() => {
    if (favFilterText !== "") {
      const filteredObject = favData.filter((item) => {
        return (
          item.name.toLowerCase().includes(favFilterText.toLowerCase()) &&
          item.fav === false
        );
      });
      setFavFilter([...filteredObject]);
    } else {
      setFavFilter([]);
    }
  }, [favFilterText, favData]);

  const onInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const formData = new FormData();
  const getIpdmUsers = async () => {
    formData.append("limit", 10);
    formData.append("search", filterText);

    try {
      const res = await postRequest("api/v1/user/users-list", formData);
      // console.log("res of users", res);

      // Assuming res contains the data you want to add to the contact list
      if (res && res.data?.users && filterText) {
        setContact((prevContacts) => [...prevContacts, ...res.data?.users]); // Append new users to the existing contacts
      } else {
        setContact(contact);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    getIpdmUsers();
  }, [filterText]);

  const favInputSearchChange = (e) => {
    setFavFilterText(e.target.value);
  };

  const onFilterClick = (prop) => {
    setFilterTab(prop);
  };

  const chatItemClick = async (id, name, item) => {
    // console.log(id, name);
    // console.log(item);
    setFilterText("");
    checkChatAvailable(id);
    setSelectedUsername(name);
    updateUserVisibility(`${name}_${id.toString()}`, true);
    const userRef = doc(
      db,
      "Users",
      `${item?.username || item?.name}_${item?.id}`
    );
    const loggedInUserId = currentUser?.id;

    try {
      // Get the document from Firestore
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        // If the user exists, set the current user data
        setCurrentUser(currentUser);

        // Check if the logged-in user's ID is in chattingIds
        if (!userDoc.data().chattingIds.includes(loggedInUserId)) {
          // Add the logged-in user's ID to chattingIds if it's not already there
          await updateDoc(userRef, {
            chattingIds: arrayUnion(loggedInUserId),
          });
        }
      } else {
        // If the user doesn't exist, create a new user in Firestore
        const newUser = {
          id: item?.id,
          name: item?.username || item?.name || "Unknown User", // Fallback to 'Unknown User'
          createdAt: new Date().toISOString(),
          profilePic: item?.profile_image_path || item.profilePic || "", // Ensure a default value if image path is missing
          messages: false,
          chattingIds: [loggedInUserId], // Initialize chattingIds with the logged-in user's ID
        };

        // Create the new user document in Firestore
        await setDoc(userRef, newUser);
      }
    } catch (error) {
      console.error("Error fetching or creating user:", error);
    }

    // Handle chat UI updates
    createNewChat(id);

    let data = contact;
    const index = data.findIndex((item) => item.id === id);
    const dataSet = data.find((item) => item.id === id);

    if (dataSet?.unread === true) {
      data[index].unread = false;
      setChat([...data]);
    }
    // Set the selected user details
    setSelectedId(id);
    setSelectedName(name);
    setSelectedUsername(name);

    // Handle mobile view toggle
    if (window.innerWidth < 860) {
      setMobileView(true);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const goBackToUserList = () => {
    setSelectedUser(null);
    setUsers(users.map((u) => ({ ...u, selected: false })));
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (authToken) {
          const res = await postRequestGetUserProfile(
            "api/v1/account/get-profile"
          );
          setProfile(res?.data?.profile); // Set profile state
        } else {
          console.log("No token found");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchUserProfile();
  }, [authToken]); // Re-fetch profile if token changes
  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (!profile || !profile?.user_id) {
        console.error("Profile or user ID is missing");
        return;
      }
      const userRef = doc(
        db,
        "Users",
        `${profile?.username}_${profile?.user_id}`.toString()
      );
      try {
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setCurrentUser(userDoc.data());
          await updateDoc(userRef, {
            active: true,
            visible: true,
          });
        } else {
          // If the user doesn't exist, create the user in Firestore
          const newUser = {
            id: profile?.user_id,
            name: profile?.username || "Unknown User", // Fallback to 'Unknown User'
            createdAt: new Date().toISOString(),
            profilePic: profile?.profile_image_path,
            active: true,
            chattingIds: [profile?.user_id], // Initialize chattingIds with the logged-in user's ID
          };
          await setDoc(userRef, newUser);
          setCurrentUser(newUser);
        }
      } catch (error) {
        console.error("Error fetching or creating user:", error);
      }
    };

    fetchCurrentUser();
  }, [profile]);

  useEffect(() => {
    if (currentUser) {
      // If the current user is fetched or created, add them to the chat users list
      setUsers((prevUsers) => {
        if (!prevUsers.some((user) => user.id === currentUser.id)) {
          return [...prevUsers, currentUser];
        }
        return prevUsers;
      });
    }
  }, [currentUser]);

  const handleSayHello = () => {
    setTextMsg("Hello 👋");
    setFilterText("");
    toggleModal();
  };

  return (
    <React.Fragment>
      <Head title={"I Party DJ MIX"}></Head>
      <ContentAlt>
        <div className="nk-chat">
          <div className={`nk-chat-aside ${mobileView ? "has-aside" : ""}`}>
            <div className="nk-chat-aside-head">
              <div className="nk-chat-aside-user">
                <UncontrolledDropdown>
                  {currentUser?.profilePic || profile?.profile_image_path ? (
                    <img
                      src={
                        currentUser?.profilePic || profile?.profile_image_path
                      } // URL of the DJ's profile image
                      alt="Profile"
                      className="user-avatar"
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                      }} // Ensure the image is rounded
                    />
                  ) : (
                    <UserAvatar
                      text={getInitiatals(
                        currentUser?.name || profile?.username || "User"
                      )}
                      theme="primary"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    ></UserAvatar>
                  )}
                  <div className="title">{mainTab}</div>
                </UncontrolledDropdown>
              </div>
              <ul className="nk-chat-aside-tools g-2">
                {mainTab === "Chats" || mainTab === "Channel" ? (
                  <React.Fragment>
                    <li></li>
                  </React.Fragment>
                ) : (
                  <li>
                    <Button color="light" className="btn-round btn-icon">
                      <Icon name="user-add-fill"></Icon>
                    </Button>
                  </li>
                )}
              </ul>
            </div>
            {mainTab === "Chats" ? (
              <ChatAsideBody
                onInputChange={onInputChange}
                filteredChatList={filteredChatList}
                favState={favState}
                favFilter={favFilter}
                setFavFilter={setFavFilter}
                setFavState={setFavState}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                favInputSearchChange={favInputSearchChange}
                favFilterText={favFilterText}
                chatItemClick={chatItemClick}
                filterTab={filterTab}
                currentUser={currentUser}
                selectedUser={selectedUser}
                messages={messages}
                filterText={filterText}
              />
            ) : mainTab === "Channel" ? (
              <ChannelAsideBody
                filteredChatList={filteredChatList}
                onInputChange={onInputChange}
                setSelectedId={setSelectedId}
                setMobileView={setMobileView}
                selectedId={selectedId}
                chatItemClick={chatItemClick}
                filterTab={filterTab}
              />
            ) : (
              <AppContact setTab={setMainTab} setSelectedId={setSelectedId} />
            )}
          </div>
          {selectedId === undefined && (
            <div className="nk-chat-body">
              <div className="nk-chat-blank">
                <div className="nk-chat-blank-btn">
                  <img src={homePageImg} />
                </div>
                <p>Select contact to chat!!</p>
              </div>
            </div>
          )}

          {selectedId !== null ? (
            <ChatBody
              id={selectedId}
              name={selectedName}
              setSelectedId={setSelectedId}
              setMobileView={setMobileView}
              mobileView={mobileView}
              username={selectedUsername}
              currentUser={currentUser}
              selectedUser={selectedUser}
              createNewChat={createNewChat}
              setTextMsg={setTextMsg}
              textMsg={textMsg}
              user={selectedUser}
              setCurrentUser={setCurrentUser}
              chatItemClick={chatItemClick}
              sharedPhotos={sharedPhotos}
            />
          ) : (
            <>
              <div className="nk-chat-body">
                <div className="nk-chat-blank">
                  <div className="nk-chat-blank-btn">
                    <img src={homePageImg} />
                  </div>
                  <p>Select contact to chat!!</p>
                </div>
              </div>
            </>
          )}
        </div>
      </ContentAlt>

      <StartChatModal
        isOpen={modal}
        toggle={toggleModal}
        name={selectedUsername}
        handleSayHello={handleSayHello}
      />
    </React.Fragment>
  );
};

export default Chat;

const StartChatModal = ({ isOpen, toggle, name, handleSayHello }) => {
  // console.log("isopen", isOpen);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    let timer;
    if (isOpen) {
      // Disable the button initially
      setIsButtonEnabled(false);

      // Set a timer to enable the button after 2 seconds
      timer = setTimeout(() => {
        setIsButtonEnabled(true);
      }, 2000);
    }

    // Cleanup the timer if the modal is closed before 2 seconds
    return () => clearTimeout(timer);
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader
        toggle={toggle}
        className="text-center"
        style={{
          borderBottom: "none",
          justifyContent: "center",
          backgroundColor: "black",
        }}
      >
        <p>Start Chat</p>
      </ModalHeader>
      <ModalBody className="text-center">
        <h5>Add {name}</h5>
        {/* <p>User will be added to your contact list</p> */}
      </ModalBody>
      <ModalFooter
        className="d-flex justify-content-center"
        style={{ borderTop: "none" }}
      >
        <Button
          color="primary"
          style={{ backgroundColor: "#De3744" }}
          onClick={handleSayHello}
          disabled={!isButtonEnabled} // Disable the button initially
        >
          Say Hello 👋
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
