const menu = [
  // {
  //   heading: "Dashboards",
  // },
  // {
  //   icon: "dashboard",
  //   text: "Overview",
  //   link: "/",
  //   dashboard: true,
  // },
  // {
  //   icon: "trend-up",
  //   text: "Trending",
  //   link: "/Trending",
  //   dashboard: true,
  // },
  // {
  //   icon: "img-fill",
  //   text: "Albums",
  //   link: "/Albums",
  //   dashboard: true,
  // },
  // {
  //   icon: "music",
  //   text: "Artists",
  //   link: "/Artists",
  //   dashboard: true,
  // },
  // {
  //   heading: "Discover",
  // },
  {
    icon: "icon ni ni-home",
    text: "Home",
    link: "/home",
    // subMenu: [
    //   {
    //     icon: "cards",
    //     text: "Project Cards",
    //     link: "/Trending",
    //   },
    //   {
    //     icon: "view-list-fill",
    //     text: "Project List",
    //     link: "/Trending",
    //   },
    // ],
  },
  {
    icon: "icon ni ni-award",
    text: "Events",
    link: "/my-events",
    // active: false,
    // subMenu: [
    //   {
    //     icon: "user-list",
    //     text: "User List - Regular",
    //     link: "/Trending",
    //   },
    // ],
  },
  {
    icon: "icon ni ni-user-circle",
    text: "Profile",
    link: "/profile",
    // active: false,
    // subMenu: [
    //   {
    //     icon: "headphone",
    //     text: "Shows",
    //     link: "/Trending",
    //   },
    // ],
  },
  {
    icon: "icon ni ni-contact",
    text: "Contact",
    link: "/contact-us",
    // active: false,
    // subMenu: [
    //   {
    //     icon: "view-list-fill",
    //     text: "Product List",
    //     link: "/Trending",
    //   },
    //   {
    //     icon: "cards",
    //     text: "Product Card",
    //     link: "/Trending",
    //   },
    //   {
    //     icon: "article",
    //     text: "Product Details",
    //     link: "/Trending",
    //   },
    // ],
  },
  {
    icon: "chat",
    text: "Messanger",
    link: "/chat",
    // subMenu: [
    //   {
    //     icon: "view-list-fill",
    //     text: "Invoice List",
    //     link: "/Trending",
    //   },
    //   {
    //     icon: "article",
    //     text: "Invoice Details",
    //     link: "/Trending",
    //   },
    // ],
  },

];
export default menu;
